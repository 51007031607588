<template>
  <div class="wrap wrapQuery">   
    <van-form input-align="right" error-message-align="right" v-if='!isEmpty'>
      <van-field readonly label="快递单号" style="display: flex;flex-direction: row;align-items: center;">
        <template #button>
          <van-button 
            round 
            v-clipboard:copy="list.couriernumber"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            style="border:none;" 
            color="#0D76FF" 
            plain
            >{{list.couriernumber}} 
            <img src='../../../assets/img/copy.png' class="copyImg" />
            </van-button>
        </template>
      </van-field>
      <van-field readonly label="邮寄地址" type="textarea" autosize rows="1" :value="list.address" />
      <van-field readonly label="收件人名称" type="textarea" autosize rows="1" :value="list.name" />
      <van-field readonly label="收件电话" :value="list.phone" />
      <van-field readonly label="邮寄日期" :value="list.couriertime" />
      <van-field readonly label="快递公司" :value="list.mailcompany" />
      <div class="footerBtn1" @click="viewLogistics">
        <div class="footerText">查看物流</div>
      </div>
    </van-form>
    <van-empty v-else
      class="mailing-image"
      :image="emptyImg"
      description="您的资料正在准备中，请耐心等待"
    />
  </div>
</template>
<script>
  import {settlemailinfos,getOpenId,querytype} from '@/server'
  import { Toast,Step, Steps } from 'vant';
  import {setCookie,getCookie} from '@/lib/utils';
  export default {
  data() {
    return {
      id: this.$route.query.id || '',
      cardId:this.$store.state.cardId,
      couriernumber: this.$route.query.couriernumber || '',
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      list: {},
      active:'',
      isEmpty: false,
      emptyImg:require('@/assets/img/mailEmpty.png')
    };
  },
  mounted(){
    // let that = this;
    // sessionStorage.setItem('key', 'mailingDetail');
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // 	if(sessionStorage.getItem('key') == 'mailingDetail') {
    //     that.$router.push({ path: '/settleInfoMail/mailingList' });
    //   }
    // }, false);
  },
  created(){
    if(this.id){
      this.getDetail()
    }else{
      this.isEmpty = true
    }
  },
  methods: {
    getDetail(){
      const params = {
        id : this.id,
      }
      settlemailinfos(params).then(res=>{
        if(res.wx_code == '502'){
          this.isEmpty = true
        }else{
          this.list = res;
        }
        
      })
    },
    onCopy(){
     Toast({
        message: "快递单号复制成功",
        duration:'3000'
      }); 
    },
    onError(){
      Toast.fail({
        message: "复制失败",
        duration:'3000'
      });
    },
    viewLogistics(){
      const querytypeparams = {
        card_id:this.cardId,
        function_type:'button',
        button_name:'查看物流',
        pre_menu:sessionStorage.getItem('menu_name')
      }
      querytype(querytypeparams).then(res=>{

      }).catch(err=>{
        Toast.fail({
          message: "网络错误",
          duration:'3000'
        });
      });
      // alert(this.couriernumber)
      setTimeout(e=>{
        // window.location.href = "https://www.baidu.com/s?wd="+this.couriernumber
        window.location.href = "https://www.baidu.com/from=844b/s?word="+this.couriernumber+"&ms=1"
      },500)
    }
  },
};
</script>
<style>
textarea{
  overflow:hidden;
}
.wrap .van-field__control {
    color: #666666 !important;
}
.upflie .van-cell__value{
  color: #666666 !important;
}
.upflie .van-cell__title{
  color: #646566;
}
.wrapQuery .van-field--min-height .van-field__control {
  min-height: 30px;
}
.vanStep{
  margin: 15px;border-radius: 5px;
}
.van-step--finish{
  color: #E50012;
}
.mailing-image img{
  width: 180px;
  height: 126px;
}
.copyImg{
  width: 17px;
  height: 17px;
  margin-left: 10px;
}
.footerBtn1{
  display: flex;
  justify-content: center;
  margin-top: 28px;
  cursor: pointer;
}
.footerText{
  width: 175px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  border: 1px solid #B9C5F8;
  border-radius: 4px;
  background-color: #EEF1FE;
  color: #4E6EF2;
}
</style>